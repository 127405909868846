<template>
  <div class="corTela">
    <b-row>
      <b-col
        md="12"
        class="tela card"
      >
        <b-card-actions
          ref="loadingCard"
          no-actions
        >
          <b-card
            title="Jogo da Memória"
            sub-title="Para iniciar pressione o botão iniciar."
          >
            <b-card-text class="alignTempo">
              Painel de Tempo
            </b-card-text>
            <div class="tempo">
              <h1 id="con">
                {{ timer }}
              </h1>
            </div>
            <b-card-text class="alignJogadas">
              Quantidade possíveis de erros
            </b-card-text>
            <div class="tempo">
              <h1>{{ jogadas }}x</h1>
            </div>
            <div>
              <div class="container Enquadramento">
                <div class="Quadrado">
                  <div
                    class="bola-vermelho button"
                    :class="{
                      'bola-active': VermelhoActive,
                      'button-disabled': bloq
                    }"
                    @mousedown="Jogada(1)"
                  />
                  <div
                    class="bola-verde button"
                    :class="{
                      'bola-active': VerdeActive,
                      'button-disabled': bloq
                    }"
                    @mousedown="Jogada(0)"
                  />
                  <div
                    class="bola-amarelo button"
                    :class="{
                      'bola-active': AmareloActive,
                      'button-disabled': bloq
                    }"
                    @mousedown="Jogada(2)"
                  />
                  <div
                    class="bola-azul button"
                    :class="{
                      'bola-active': AzulActive,
                      'button-disabled': bloq
                    }"
                    @mousedown="Jogada(3)"
                  />
                  <div class="painel text-center">
                    <div class="row button-group">
                      <div class="col-xs-4">
                        <h1 class="NomeCentro">
                          Acertos
                        </h1>
                        <div class="Pontos">
                          {{ acertos }}
                        </div>
                      </div>
                      <div class="col-xs-4">
                        <div class="">
                          <b-button
                            v-if="isActive === false"
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            variant="outline-danger buttonResp"
                            @click="
                              toggleMudar()
                              start()
                            "
                          >
                            <span
                              class="align-middle buttonMiddle"
                            >Iniciar</span>
                          </b-button>
                          <!-- <div class="btn Prime" @click="toggleMudar()" v-if="MudarOn === true">Encerrar</div> -->
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row VerdeVermelho">
                    <div
                      class="Verde button col-xs-5"
                      :class="{
                        'Verde-active': VerdeActive,
                        'button-disabled': bloq
                      }"
                      @click="
                        {
                          clickCor('verde'), Jogada(0)
                        }
                      "
                    />
                    <div class="QuadradoY col-xs-2" />
                    <div
                      class="Vermelho button col-xs-5"
                      :class="{
                        'Vermelho-active': VermelhoActive,
                        'button-disabled': bloq
                      }"
                      @click="
                        {
                          clickCor('vermelho'), Jogada(1)
                        }
                      "
                    />
                  </div>
                  <div class="row QuadradoX" />
                  <div class="row AmareloAzul">
                    <div
                      class="Amarelo button col-xs-5"
                      :class="{
                        'Amarelo-active': AmareloActive,
                        'button-disabled': bloq
                      }"
                      @click="
                        {
                          clickCor('amarelo'), Jogada(2)
                        }
                      "
                    />
                    <div class="QuadradoY col-xs-2" />
                    <div
                      class="Azul button col-xs-5"
                      :class="{
                        'Azul-active': AzulActive,
                        'button-disabled': bloq
                      }"
                      @click="
                        {
                          clickCor('azul'), Jogada(3)
                        }
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
          </b-card>
        </b-card-actions>
      </b-col>
      <b-col md="11">
        <div class="text-right inf">
          <b-badge
            v-if="memorize == true"
            pill
            variant="light-danger"
          >
            Memorize
          </b-badge>
          <b-badge
            v-if="jogue == true"
            pill
            variant="light-success"
          >
            Jogue
          </b-badge>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton,
  BFormGroup,
  BFormInput,
  BCard,
  BRow,
  BCol,
  BCardText,
  BBadge
} from "bootstrap-vue"
import Ripple from "vue-ripple-directive"
import _ from "lodash"
import BCardActions from "@core/components/b-card-actions/BCardActions.vue"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import useJwt from "@/auth/jwt/useJwt"
import Button from "@/views/components/button/Button.vue"
import { getHomeRouteForLoggedInUser, getUserData } from "@/auth/utils"
import { required } from "vee-validate/dist/rules"
import stringify from "uuid/dist/stringify"

export default {
  components: {
    BCardActions,
    BCard,
    BRow,
    BCol,
    BCardText,
    BBadge,
    // Button,
    BButton
  },
  directives: {
    Ripple
  },
  // props:{
  //   routeAtual: {
  //     default: this.$router.history.current.path,
  //     type: String,
  //     required: false
  //     }

  // },

  data() {
    return {
      consultarGame: {
        idDoGame: 0,
        quantidadeDeJogadas: 0,
        tempoMaximo: 0,
        quantidadeDeTeste: 0
      },
      ResultadoGame: {
        idDoGame: 0,
        quantidadeDeAcertos: 0,
        quantidadeDeErros: 0,
        tempoDaPartida: 0,
        quantidadeDeOmissao: 0
      },
      VerdeActive: false,
      VermelhoActive: false,
      AmareloActive: false,
      AzulActive: false,
      modoFull: false,
      recebeRota: location.pathname,
      h: 0,
      m: 0,
      s: 0,
      jogadas: null,
      cadastra: false,
      tempoPartida: 0,
      resultadoTempo: 0,
      ativaTempo: false,
      acertos: 0,
      tempo: 1000,
      timer: "0000",
      minutos: 0,
      segundos: 0,
      duracao: 10,
      cron: "",
      formato: "",
      iniciar: false,
      MudarOn: false,
      MudarOff: false,
      bloq: true,
      isActive: false,
      maximoLevel: 50,
      contar: "",
      cores: {
        0: "Verde",
        1: "Vermelho",
        2: "Amarelo",
        3: "Azul"
      },
      sequencia: [],
      Passos: [],
      memorize: false,
      jogue: false
    }
  },
  computed: {},
  watch: {},
  created() {
    window.addEventListener("keyup", e => {
      e = e || document.e
      if (e.code == "ArrowUp") {
        // up arrow
        this.Jogada(1)
        this.VermelhoActive = true
        setTimeout(() => {
          this.VermelhoActive = false
        }, 100)
      } else if (e.code == "ArrowDown") {
        // down arrow
        this.Jogada(2)
        this.AmareloActive = true
        setTimeout(() => {
          this.AmareloActive = false
        }, 100)
      } else if (e.code == "ArrowLeft") {
        // left arrow
        this.Jogada(0)
        this.VerdeActive = true
        setTimeout(() => {
          this.VerdeActive = false
        }, 100)
      } else if (e.code == "ArrowRight") {
        // right arrow
        this.Jogada(3)
        this.AzulActive = true
        setTimeout(() => {
          this.AzulActive = false
        }, 100)
      }
    }),
      window.addEventListener(
        "keydown",
        function(e) {
          if (
            [
              "Space",
              "ArrowUp",
              "ArrowDown",
              "ArrowLeft",
              "ArrowRight"
            ].indexOf(e.code) > -1
          ) {
            e.preventDefault()
          }
        },
        false
      )
  },
  mounted() {
    this.$refs["loadingCard"].showLoading = true
    this.ConsultaMemoria()
  },
  methods: {
    clickCor(cor) {
      switch (cor) {
        case "vermelho": {
          this.VermelhoActive = true
          setTimeout(() => {
            this.VermelhoActive = false
          }, 100)
          break
        }
        case "verde": {
          this.VerdeActive = true
          setTimeout(() => {
            this.VerdeActive = false
          }, 100)
          break
        }
        case "amarelo": {
          this.AmareloActive = true
          setTimeout(() => {
            this.AmareloActive = false
          }, 100)
          break
        }
        case "azul": {
          this.AzulActive = true
          setTimeout(() => {
            this.AzulActive = false
          }, 100)
          break
        }
      }
    },

    autoKey() {
      return String(Math.floor(Math.random() * 100))
    },

    bloqscroll() {
      $(document).keydown(function(evt) {
        var key = evt.keyCode
        if (
          (key === 38 || key === 40) &&
          !/^(textarea|input|select)$/i.test(evt.target.tagName)
        ) {
          return false
        }
      })
    },

    verificaTempoPartida() {
      this.resultadoTempo = this.consultarGame.tempoMaximo - this.tempoPartida
      if (this.resultadoTempo > this.consultarGame.tempoMaximo) {
        this.resultadoTempo = this.consultarGame.tempoMaximo
      }
    },

    async CadastrarGame() {
      if (this.cadastra == false) {
        this.verificaTempoPartida()
        const resultado = this.ResultadoGame
        resultado.idDoGame = this.consultarGame.idDoGame
        resultado.quantidadeDeOmissao = this.jogadas
        resultado.tempoDaPartida = this.resultadoTempo
        // console.log("POST: ", resultado)
        this.MensagemCadastrandoGame("warning", "top-center")
        const resp = await useJwt
          .cadastroMemoria(resultado)
          .then(response => response.data)
          .catch(response => {
            setTimeout(() => {
              const catchError = Object.assign({}, response)
              this.$router.push("/teste/selecao")
              return this.MensagemErroCadastro("danger", "top-center")
            }, 3000)
          })
        // setTimeout(() =>{
        //      this.MensagemCadastradoSucesso()
        // }, 6000)
        if (resp.sucesso) {
          setTimeout(() => {
            this.$router.push("/teste/selecao")
            this.MensagemCadastradoSucesso("success", "top-center")
          }, 3000)
        }
      }
    },
    // MensagemCadastrandoGame() {
    //   return this.$toast({
    //     component: ToastificationContent,
    //     timeout: 2000,
    //     props: {
    //       title: "O jogo foi finalizado!",
    //       text: "Por favor aguarde enquanto o sistema registra o resultado.",
    //       icon: "ActivityIcon",
    //       variant: "warning"
    //     }
    //   })
    // },
    // MensagemCadastradoSucesso() {
    //   const nomeDoUsuario = getUserData().nomeDoUsuario
    //   this.$toast({
    //     component: ToastificationContent,
    //     position: "top-right",
    //     timeout: 2000,
    //     props: {
    //       title: `Parabéns ${nomeDoUsuario}`,
    //       icon: "GiftIcon",
    //       variant: "success",
    //       text: "O jogo foi concluído com sucesso!"
    //     }
    //   })
    // },
    async ConsultaMemoria() {
      const resp = await useJwt
        .consultaMemoria()
        .then(response => response.data)
        .catch(response => {
          this.$refs["loadingCard"].showLoading = false
          const catchError = Object.assign({}, response)
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            timeout: 2000,
            props: {
              title: "Ocorreu um erro ao carregar o jogo.",
              icon: "AlertTriangleIcon",
              variant: "danger",
              text: catchError
            }
          })
        })
      if (resp.sucesso) {
        this.consultarGame = resp.dados.consultarGame
        this.jogadas = this.consultarGame.quantidadeDeJogadas
        this.$refs["loadingCard"].showLoading = false
        // this.jogadas = 5
      }
    },
    toggleMudar() {
      this.MudarOn = !this.MudarOn
      if (this.MudarOn) {
        this.contar = "- -"
        this.isActive = true
        this.IniciarTempo()
      } else {
        this.MudarOn = false
        this.sequencia = []
        this.Passos = []
        this.contar = ""
        this.bloq = true
      }
    },
    start: _.debounce(function() {
      if (!this.MudarOn) {
        return
      }
      this.contar = "- -"
      this.sequencia = []
      this.Passos = []
      setTimeout(() => {
        this.Adicionar()
        this.contar = this.sequencia.length

        this.repetir()
      }, 1000)
    }, 1000),

    AdicionarRepeticao() {
      this.Adicionar()
      this.repetir()
    },
    Adicionar() {
      const index = Math.floor(Math.random() * 4)
      this.sequencia.push(index)
    },
    Reproduzir(index) {
      const color = this.cores[index]
      const activeClass = `${color}Active`
      this[activeClass] = true
      setTimeout(() => {
        this[activeClass] = false
      }, 700)
    },
    Jogada(index) {
      if (
        this.bloq ||
        !this.MudarOn ||
        this.sequencia.length == this.Passos.length
      ) {
        return
      }
      this.Passos.push(index)
      const etapaFim = this.sequencia[this.sequencia.length - 1]
      const ultimoIndex = this.Passos.length - 1
      if (this.sequencia[ultimoIndex] != index) {
        this.contar = "errou"
        this.acertos = this.contar
        if (this.contar === "errou") {
          setTimeout(this.start, 1000)
          this.ResultadoGame.quantidadeDeErros++
          // console.log("Erros: ", this.ResultadoGame.quantidadeDeErros)
          this.jogadas = this.jogadas - 1
          if (--this.timer == -1 || this.jogadas == 0) {
            if (this.tempoPartida == 0) {
              this.tempoPartida = this.timer
            }
            setTimeout((this.isActive = true), 1000)
            this.CadastrarGame()
            this.cadastra = true
          }
        } else {
          setTimeout(this.repetir, 1000)
        }
        return
      }

      if (this.sequencia.length == this.Passos.length) {
        if (this.contar == this.maximoLevel) {
          this.contar = "PARABENS"
          setTimeout(this.start, 2000)
          return
        }
        setTimeout(() => {
          this.contar++
          this.ResultadoGame.quantidadeDeAcertos++
          // console.log("Pontos: ", this.ResultadoGame.quantidadeDeAcertos)
          this.acertos = this.ResultadoGame.quantidadeDeAcertos
          this.AdicionarRepeticao()
        }, 1000)
      }
    },
    repetir() {
      this.Passos = []
      this.contar = this.sequencia.length
      this.acertos = this.ResultadoGame.quantidadeDeAcertos
      this.bloq = true
      this.jogue = false
      this.memorize = true
      this.sequencia.forEach((value, index) => {
        setTimeout(() => {
          this.Reproduzir(value)
          if (index == this.sequencia.length - 1) {
            setTimeout(() => {
              this.bloq = false
              this.memorize = false
              this.jogue = true
            }, 1000)
          }
        }, 1000 * (index + 1))
      })
    },

    resetTimer() {
      clearInterval(this.cron)
    },

    IniciarTempo() {
      this.cron = setInterval(() => this.ContagemTempo(), this.tempo)
    },

    ContagemTempo() {
      if (this.timer === "0000") {
        this.timer = this.consultarGame.tempoMaximo
        // this.timer = 60
      }
      this.timer
      // console.log("Resultado Tempo: ", this.tempoPartida)
      this.minutos = parseInt(this.timer / 60, 10)
      this.segundos = parseInt(this.timer % 60, 10)
      // console.log("Tempo: ", this.timer)
      this.formato =
        (this.minutos < 10 ? "0" + this.minutos : this.minutos) +
        ":" +
        (this.segundos < 10 ? "0" + this.segundos : this.segundos)
      document.getElementById("con").innerText = this.formato
      if (--this.timer == -1 || this.jogadas == 0) {
        if (this.tempoPartida == 0) {
          this.tempoPartida = this.timer
        }
        this.CadastrarGame()
        this.cadastra = true
        this.toggleMudar()
        this.resetTimer()
        this.isActive = true
        this.timer = "00:00"
        return this.formato
        // this.timer = this.duracao
      }
      return this.formato
    },
    MensagemCadastrandoGame(variant, position) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "O jogo foi finalizado!",
            text: "Por favor aguarde enquanto o sistema registra o resultado.",
            icon: "ActivityIcon",
            variant
          }
        },
        {
          position
        }
      )
    },
    MensagemCadastradoSucesso(variant, position) {
      const nomeDoUsuario = getUserData().nomeDoUsuario
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Parabéns " + nomeDoUsuario,
            icon: "GiftIcon",
            text: "O jogo foi concluído com sucesso!",
            variant
          }
        },
        {
          position
        }
      )
    },
    MensagemErroCadastro(variant, position) {
      console.log("entrando aqui no erro da mensagem ao salvar resultado")
      const nomeDoUsuario = getUserData().nomeDoUsuario
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Ocorreu um erro ao salvar o resultado.",
            icon: "AlertTriangleIcon",
            text: "Por favor, tente novamente.",
            variant
          }
        },
        {
          position
        }
      )
    }
  }
}
</script>
<style scoped>
body {
  font-family: "Maven Pro", sans-serif;
}

.float-right {
  float: right;
}
.alinhamento {
  margin-top: -43px;
  margin-left: 160px;
}
.tela {
  height: 100px;
  box-shadow: none !important;
}
.card {
  box-shadow: none !important;
}
.corTela {
  background-color: white !important;
  height: 100vh !important;
  background-size: cover;
}

.painel {
  position: absolute;
  background: white;
  width: 240px;
  height: 240px;
  border-radius: 50%;
  z-index: 5;
  margin: 105px;
  border: 10px solid #232020;
  padding: 10px;
  transform: rotate(45deg);
}

.painel .small {
  position: relative;
  top: -25px;
  right: 8px;
  font-size: 20px;
}

.tempo {
  font-size: 20px;
  width: 160px;
  height: 35px;
  margin-left: 33px;
  margin-top: 10px;
  text-align: center;
  background: #070707e5;
  box-shadow: 0px 0px 5px #232020;
  border-radius: 5px;
  font-weight: bold;
  color: red;
}

.inf {
  font-size: 40px;
  margin-right: 200px;
}

.Pontos {
  font-size: 20px;
  width: 160px;
  height: 35px;
  margin-left: 33px;
  margin-top: 10px;
  background: #070707e5;
  box-shadow: 0px 0px 5px #232020;
  border-radius: 5px;
  font-weight: bold;
  color: red;
}
.NomeCentro {
  font-size: 25px;
  font-weight: bolder;
  margin-left: 30px;
  margin-top: 30px;
  color: #232020d0;
}

.painel-button {
  margin-top: 70px;
  margin-left: -60px;
  width: 22px;
  height: 22px;
  background: red;
  border-radius: 20%;
  border: 3px solid #232020;
  box-shadow: 0px 2px 1px #232020;
  cursor: pointer;
}

.painel-button:active {
  transform: translate(0px, 1px);
  box-shadow: 0px 0px 1px #232020;
}

.painel-button {
  background: yellow;
}

.painel .text {
  font-size: 0.8em;
  margin-top: 10px;
}

.button-group {
  margin-bottom: 10px;
}

.painel .Pontos {
  margin-bottom: -3px;
}
.Mudar {
  background: deepskyblue;
  width: 16px;
  height: 16px;
  margin: 2px 1px 1px 1px;
  border-radius: 3px;
}
.scroll {
  overflow: hidden;
}

.Enquadramento {
  height: 600px;
}
.alignTempo {
  margin-left: 50px;
  margin-top: 50px;
}

.Quadrado {
  background: #333;
  width: 500px;
  height: 500px;
  box-shadow: 0px 0px 15px #232020;
  border: 25px solid #232020;
  overflow: hidden;
  margin: 0 auto;
  transform: rotate(-45deg);
  margin-top: -10%;
  position: relative;
}

.QuadradoX {
  background: #232020;
  height: 25px;
}

.QuadradoY {
  background: #232020;
  width: 25px;
  height: 230px;
}

.button {
  width: 220px;
  height: 240px;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
}

.Verde,
.Vermelho {
  height: 230px;
}

.Verde {
  background: darkgreen;
}

.Verde-active {
  background: green;
}

.Vermelho {
  background: darkred;
}

.Vermelho-active {
  background: red;
}

.Amarelo {
  background: yellowgreen;
}

.Amarelo-active {
  background: yellow;
}

.Azul {
  background: darkblue;
}

.Azul-active {
  background: blue;
}

.button-disabled {
  cursor: default;
}

.buttonTeste {
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.btn {
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  border: 2px solid #e74c3c;
  border-radius: 0.6em;
  color: #e74c3c;
  cursor: pointer;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  margin: 20px;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  margin-top: 25px;
  margin-left: 62px;
}
.btn:hover,
.btn:focus {
  color: #fff;
  outline: 0;
}

.Prime {
  -webkit-transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
  transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
}
.Prime:hover {
  box-shadow: 0 0 40px 40px #e74c3c inset;
}

.bola-vermelho {
  width: 50px;
  height: 50px;
  /* background: darkred; */
  left: 380px;
  top: 20px;
  border-radius: 50%;
  position: absolute;
}
.bola-verde {
  width: 50px;
  height: 50px;
  /* background: darkgreen; */
  left: 20px;
  top: 20px;
  border-radius: 50%;
  position: absolute;
}

.bola-amarelo {
  width: 50px;
  height: 50px;
  /* background: yellowgreen; */
  left: 20px;
  top: 380px;
  border-radius: 50%;
  position: absolute;
}

.bola-azul {
  width: 50px;
  height: 50px;
  /* background: darkblue; */
  left: 380px;
  top: 380px;
  border-radius: 50%;
  position: absolute;
}

.preto:active,
.bola-active {
  background: rgb(1, 6, 32);
}

.alignJogadas {
  margin-left: 10px;
  margin-top: 20px;
}
@media only screen and (max-width: 2560px) {
  .Verde,
  .Vermelho {
    height: 200px;
  }
  .Amarelo,
  .Azul {
    height: 200px;
  }

  .VerdeVermelho {
    height: 150px;
  }

  .AmareloAzul {
    height: 150px;
  }

  .painel {
    position: absolute;
    background: white;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    z-index: 5;
    margin: 63px;
    border: 10px solid #232020;
    padding: 10px;
    transform: rotate(45deg);
  }

  .painel-button {
    margin-top: 70px;
    margin-left: -60px;
    width: 22px;
    height: 22px;
    background: red;
    border-radius: 20%;
    border: 3px solid #232020;
    box-shadow: 0px 2px 1px #232020;
    cursor: pointer;
  }

  .painel-button:active {
    transform: translate(0px, 1px);
    box-shadow: 0px 0px 1px #232020;
  }

  .painel-button {
    background: yellow;
  }

  .painel .text {
    font-size: 0.8em;
    margin-top: 10px;
  }

  .button-group {
    margin-bottom: 10px;
  }

  .painel .Pontos {
    margin-bottom: -3px;
  }

  .NomeCentro {
    font-size: 25px;
    font-weight: bolder;
    margin-left: 30px;
    margin-top: 20px;
    color: #232020d0;
  }

  .Pontos {
    font-size: 20px;
    width: 120px;
    height: 35px;
    margin-left: 33px;
    margin-top: 10px;
    background: #070707e5;
    box-shadow: 0px 0px 5px #232020;
    border-radius: 5px;
    font-weight: bold;
    color: red;
  }

  .buttonResp {
    width: 85px;
    margin-left: 50px;
    margin-top: 15px;
  }
  .buttonMiddle {
    margin-left: -6px;
  }

  .button {
    width: 169px;
    height: 150px;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
  }
  .Enquadramento {
    height: 400px;
  }

  .Quadrado {
    background: #333;
    width: 386px;
    height: 375px;

    box-shadow: 0px 0px 15px #232020;
    border: 25px solid #232020;
    overflow: hidden;
    margin: 0 auto;
    transform: rotate(-45deg);
    margin-top: -10%;
    position: relative;
  }

  .QuadradoX {
    background: #232020;
    width: 430px;
    height: 25px;
  }

  .QuadradoY {
    background: #232020;
    width: 25px;
    height: 230px;
  }

  .bola-vermelho {
    width: 50px;
    height: 50px;
    /* background: darkred; */
    left: 287px;
    top: 0px;
    border-radius: 50%;
    position: absolute;
  }
  .bola-verde {
    width: 50px;
    height: 50px;
    /* background: darkgreen; */
    left: 0px;
    top: 0px;
    border-radius: 50%;
    position: absolute;
  }

  .bola-amarelo {
    width: 50px;
    height: 50px;
    /* background: yellowgreen; */
    left: 0px;
    top: 275px;
    border-radius: 50%;
    position: absolute;
  }
  .bola-azul {
    width: 50px;
    height: 50px;
    /* background: darkblue; */
    left: 285px;
    top: 275px;
    border-radius: 50%;
    position: absolute;
  }
}

@media only screen and (max-width: 1664px) {
  .Verde,
  .Vermelho {
    height: 200px;
  }
  .Amarelo,
  .Azul {
    height: 200px;
  }

  .VerdeVermelho {
    height: 150px;
  }

  .AmareloAzul {
    height: 150px;
  }

  .painel {
    position: absolute;
    background: white;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    z-index: 5;
    margin: 63px;
    border: 10px solid #232020;
    padding: 10px;
    transform: rotate(45deg);
  }

  .painel-button {
    margin-top: 70px;
    margin-left: -60px;
    width: 22px;
    height: 22px;
    background: red;
    border-radius: 20%;
    border: 3px solid #232020;
    box-shadow: 0px 2px 1px #232020;
    cursor: pointer;
  }

  .painel-button:active {
    transform: translate(0px, 1px);
    box-shadow: 0px 0px 1px #232020;
  }

  .painel-button {
    background: yellow;
  }

  .painel .text {
    font-size: 0.8em;
    margin-top: 10px;
  }

  .button-group {
    margin-bottom: 10px;
  }

  .painel .Pontos {
    margin-bottom: -3px;
  }

  .NomeCentro {
    font-size: 25px;
    font-weight: bolder;
    margin-left: 30px;
    margin-top: 20px;
    color: #232020d0;
  }

  .Pontos {
    font-size: 20px;
    width: 120px;
    height: 35px;
    margin-left: 33px;
    margin-top: 10px;
    background: #070707e5;
    box-shadow: 0px 0px 5px #232020;
    border-radius: 5px;
    font-weight: bold;
    color: red;
  }

  .buttonResp {
    width: 85px;
    margin-left: 50px;
    margin-top: 15px;
  }
  .buttonMiddle {
    margin-left: -6px;
  }

  .button {
    width: 169px;
    height: 150px;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
  }
  .Enquadramento {
    height: 400px;
  }

  .Quadrado {
    background: #333;
    width: 386px;
    height: 375px;

    box-shadow: 0px 0px 15px #232020;
    border: 25px solid #232020;
    overflow: hidden;
    margin: 0 auto;
    transform: rotate(-45deg);
    margin-top: -10%;
    position: relative;
  }

  .QuadradoX {
    background: #232020;
    width: 430px;
    height: 25px;
  }

  .QuadradoY {
    background: #232020;
    width: 25px;
    height: 230px;
  }

  .bola-vermelho {
    width: 50px;
    height: 50px;
    /* background: darkred; */
    left: 287px;
    top: 0px;
    border-radius: 50%;
    position: absolute;
  }
  .bola-verde {
    width: 50px;
    height: 50px;
    /* background: darkgreen; */
    left: 0px;
    top: 0px;
    border-radius: 50%;
    position: absolute;
  }

  .bola-amarelo {
    width: 50px;
    height: 50px;
    /* background: yellowgreen; */
    left: 0px;
    top: 275px;
    border-radius: 50%;
    position: absolute;
  }
  .bola-azul {
    width: 50px;
    height: 50px;
    /* background: darkblue; */
    left: 285px;
    top: 275px;
    border-radius: 50%;
    position: absolute;
  }
}
@media only screen and (max-width: 1540px) {
  .Verde,
  .Vermelho {
    height: 200px;
  }
  .Amarelo,
  .Azul {
    height: 200px;
  }

  .VerdeVermelho {
    height: 150px;
  }

  .AmareloAzul {
    height: 150px;
  }

  .painel {
    position: absolute;
    background: white;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    z-index: 5;
    margin: 63px;
    border: 10px solid #232020;
    padding: 10px;
    transform: rotate(45deg);
  }

  .painel-button {
    margin-top: 70px;
    margin-left: -60px;
    width: 22px;
    height: 22px;
    background: red;
    border-radius: 20%;
    border: 3px solid #232020;
    box-shadow: 0px 2px 1px #232020;
    cursor: pointer;
  }

  .painel-button:active {
    transform: translate(0px, 1px);
    box-shadow: 0px 0px 1px #232020;
  }

  .painel-button {
    background: yellow;
  }

  .painel .text {
    font-size: 0.8em;
    margin-top: 10px;
  }

  .button-group {
    margin-bottom: 10px;
  }

  .painel .Pontos {
    margin-bottom: -3px;
  }

  .NomeCentro {
    font-size: 25px;
    font-weight: bolder;
    margin-left: 30px;
    margin-top: 20px;
    color: #232020d0;
  }

  .Pontos {
    font-size: 20px;
    width: 120px;
    height: 35px;
    margin-left: 33px;
    margin-top: 10px;
    background: #070707e5;
    box-shadow: 0px 0px 5px #232020;
    border-radius: 5px;
    font-weight: bold;
    color: red;
  }

  .buttonResp {
    width: 85px;
    margin-left: 50px;
    margin-top: 15px;
  }
  .buttonMiddle {
    margin-left: -6px;
  }

  .button {
    width: 169px;
    height: 150px;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
  }
  .Enquadramento {
    height: 400px;
  }

  .Quadrado {
    background: #333;
    width: 386px;
    height: 375px;
    box-shadow: 0px 0px 15px #232020;
    border: 25px solid #232020;
    overflow: hidden;
    margin: 0 auto;
    transform: rotate(-45deg);
    margin-top: -10%;
    position: relative;
  }

  .QuadradoX {
    background: #232020;
    width: 430px;
    height: 25px;
  }

  .QuadradoY {
    background: #232020;
    width: 25px;
    height: 230px;
  }

  .bola-vermelho {
    width: 50px;
    height: 50px;
    /* background: darkred; */
    left: 287px;
    top: 0px;
    border-radius: 50%;
    position: absolute;
  }
  .bola-verde {
    width: 50px;
    height: 50px;
    /* background: darkgreen; */
    left: 0px;
    top: 0px;
    border-radius: 50%;
    position: absolute;
  }

  .bola-amarelo {
    width: 50px;
    height: 50px;
    /* background: yellowgreen; */
    left: 0px;
    top: 275px;
    border-radius: 50%;
    position: absolute;
  }
  .bola-azul {
    width: 50px;
    height: 50px;
    /* background: darkblue; */
    left: 285px;
    top: 275px;
    border-radius: 50%;
    position: absolute;
  }
}
@media only screen and (max-width: 1440px) {
  .Verde,
  .Vermelho {
    height: 200px;
  }
  .Amarelo,
  .Azul {
    height: 200px;
  }

  .VerdeVermelho {
    height: 150px;
  }

  .AmareloAzul {
    height: 150px;
  }

  .painel {
    position: absolute;
    background: white;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    z-index: 5;
    margin: 63px;
    border: 10px solid #232020;
    padding: 10px;
    transform: rotate(45deg);
  }

  .painel-button {
    margin-top: 70px;
    margin-left: -60px;
    width: 22px;
    height: 22px;
    background: red;
    border-radius: 20%;
    border: 3px solid #232020;
    box-shadow: 0px 2px 1px #232020;
    cursor: pointer;
  }

  .painel-button:active {
    transform: translate(0px, 1px);
    box-shadow: 0px 0px 1px #232020;
  }

  .painel-button {
    background: yellow;
  }

  .painel .text {
    font-size: 0.8em;
    margin-top: 10px;
  }

  .button-group {
    margin-bottom: 10px;
  }

  .painel .Pontos {
    margin-bottom: -3px;
  }

  .NomeCentro {
    font-size: 25px;
    font-weight: bolder;
    margin-left: 30px;
    margin-top: 20px;
    color: #232020d0;
  }

  .Pontos {
    font-size: 20px;
    width: 120px;
    height: 35px;
    margin-left: 33px;
    margin-top: 10px;
    background: #070707e5;
    box-shadow: 0px 0px 5px #232020;
    border-radius: 5px;
    font-weight: bold;
    color: red;
  }

  .buttonResp {
    width: 85px;
    margin-left: 50px;
    margin-top: 15px;
  }
  .buttonMiddle {
    margin-left: -6px;
  }

  .button {
    width: 169px;
    height: 150px;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
  }
  .Enquadramento {
    height: 400px;
  }

  .Quadrado {
    background: #333;
    width: 386px;
    height: 375px;

    box-shadow: 0px 0px 15px #232020;
    border: 25px solid #232020;
    overflow: hidden;
    margin: 0 auto;
    transform: rotate(-45deg);
    margin-top: -10%;
    position: relative;
  }

  .QuadradoX {
    background: #232020;
    width: 430px;
    height: 25px;
  }

  .QuadradoY {
    background: #232020;
    width: 25px;
    height: 230px;
  }

  .bola-vermelho {
    width: 50px;
    height: 50px;
    /* background: darkred; */
    left: 287px;
    top: 0px;
    border-radius: 50%;
    position: absolute;
  }
  .bola-verde {
    width: 50px;
    height: 50px;
    /* background: darkgreen; */
    left: 0px;
    top: 0px;
    border-radius: 50%;
    position: absolute;
  }

  .bola-amarelo {
    width: 50px;
    height: 50px;
    /* background: yellowgreen; */
    left: 0px;
    top: 275px;
    border-radius: 50%;
    position: absolute;
  }
  .bola-azul {
    width: 50px;
    height: 50px;
    /* background: darkblue; */
    left: 285px;
    top: 275px;
    border-radius: 50%;
    position: absolute;
  }
}
@media only screen and (max-width: 1030px) {
  .Verde,
  .Vermelho {
    height: 200px;
  }
  .Amarelo,
  .Azul {
    height: 200px;
  }

  .VerdeVermelho {
    height: 150px;
  }

  .AmareloAzul {
    height: 150px;
  }

  .painel {
    position: absolute;
    background: white;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    z-index: 5;
    margin: 63px;
    border: 10px solid #232020;
    padding: 10px;
    transform: rotate(45deg);
  }

  .painel-button {
    margin-top: 70px;
    margin-left: -60px;
    width: 22px;
    height: 22px;
    background: red;
    border-radius: 20%;
    border: 3px solid #232020;
    box-shadow: 0px 2px 1px #232020;
    cursor: pointer;
  }

  .painel-button:active {
    transform: translate(0px, 1px);
    box-shadow: 0px 0px 1px #232020;
  }

  .painel-button {
    background: yellow;
  }

  .painel .text {
    font-size: 0.8em;
    margin-top: 10px;
  }

  .button-group {
    margin-bottom: 10px;
  }

  .painel .Pontos {
    margin-bottom: -3px;
  }

  .NomeCentro {
    font-size: 25px;
    font-weight: bolder;
    margin-left: 30px;
    margin-top: 20px;
    color: #232020d0;
  }

  .Pontos {
    font-size: 20px;
    width: 120px;
    height: 35px;
    margin-left: 33px;
    margin-top: 10px;
    background: #070707e5;
    box-shadow: 0px 0px 5px #232020;
    border-radius: 5px;
    font-weight: bold;
    color: red;
  }

  .buttonResp {
    width: 85px;
    margin-left: 50px;
    margin-top: 15px;
  }
  .buttonMiddle {
    margin-left: -6px;
  }

  .button {
    width: 169px;
    height: 150px;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
  }
  .Enquadramento {
    height: 400px;
  }

  .Quadrado {
    background: #333;
    width: 386px;
    height: 375px;

    box-shadow: 0px 0px 15px #232020;
    border: 25px solid #232020;
    overflow: hidden;
    margin: 0 auto;
    transform: rotate(-45deg);
    margin-top: -10%;
    margin-left: 300px;
    position: relative;
  }

  .QuadradoX {
    background: #232020;
    width: 430px;
    height: 25px;
  }

  .QuadradoY {
    background: #232020;
    width: 25px;
    height: 230px;
  }

  .bola-vermelho {
    width: 50px;
    height: 50px;
    /* background: darkred; */
    left: 287px;
    top: 0px;
    border-radius: 50%;
    position: absolute;
  }
  .bola-verde {
    width: 50px;
    height: 50px;
    /* background: darkgreen; */
    left: 0px;
    top: 0px;
    border-radius: 50%;
    position: absolute;
  }

  .bola-amarelo {
    width: 50px;
    height: 50px;
    /* background: yellowgreen; */
    left: 0px;
    top: 275px;
    border-radius: 50%;
    position: absolute;
  }
  .bola-azul {
    width: 50px;
    height: 50px;
    /* background: darkblue; */
    left: 285px;
    top: 275px;
    border-radius: 50%;
    position: absolute;
  }
}
</style>
