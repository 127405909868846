var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"corTela"},[_c('b-row',[_c('b-col',{staticClass:"tela card",attrs:{"md":"12"}},[_c('b-card-actions',{ref:"loadingCard",attrs:{"no-actions":""}},[_c('b-card',{attrs:{"title":"Jogo da Memória","sub-title":"Para iniciar pressione o botão iniciar."}},[_c('b-card-text',{staticClass:"alignTempo"},[_vm._v(" Painel de Tempo ")]),_c('div',{staticClass:"tempo"},[_c('h1',{attrs:{"id":"con"}},[_vm._v(" "+_vm._s(_vm.timer)+" ")])]),_c('b-card-text',{staticClass:"alignJogadas"},[_vm._v(" Quantidade possíveis de erros ")]),_c('div',{staticClass:"tempo"},[_c('h1',[_vm._v(_vm._s(_vm.jogadas)+"x")])]),_c('div',[_c('div',{staticClass:"container Enquadramento"},[_c('div',{staticClass:"Quadrado"},[_c('div',{staticClass:"bola-vermelho button",class:{
                    'bola-active': _vm.VermelhoActive,
                    'button-disabled': _vm.bloq
                  },on:{"mousedown":function($event){return _vm.Jogada(1)}}}),_c('div',{staticClass:"bola-verde button",class:{
                    'bola-active': _vm.VerdeActive,
                    'button-disabled': _vm.bloq
                  },on:{"mousedown":function($event){return _vm.Jogada(0)}}}),_c('div',{staticClass:"bola-amarelo button",class:{
                    'bola-active': _vm.AmareloActive,
                    'button-disabled': _vm.bloq
                  },on:{"mousedown":function($event){return _vm.Jogada(2)}}}),_c('div',{staticClass:"bola-azul button",class:{
                    'bola-active': _vm.AzulActive,
                    'button-disabled': _vm.bloq
                  },on:{"mousedown":function($event){return _vm.Jogada(3)}}}),_c('div',{staticClass:"painel text-center"},[_c('div',{staticClass:"row button-group"},[_c('div',{staticClass:"col-xs-4"},[_c('h1',{staticClass:"NomeCentro"},[_vm._v(" Acertos ")]),_c('div',{staticClass:"Pontos"},[_vm._v(" "+_vm._s(_vm.acertos)+" ")])]),_c('div',{staticClass:"col-xs-4"},[_c('div',{},[(_vm.isActive === false)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-danger buttonResp"},on:{"click":function($event){_vm.toggleMudar()
                            _vm.start()}}},[_c('span',{staticClass:"align-middle buttonMiddle"},[_vm._v("Iniciar")])]):_vm._e()],1)])])]),_c('div',{staticClass:"row VerdeVermelho"},[_c('div',{staticClass:"Verde button col-xs-5",class:{
                      'Verde-active': _vm.VerdeActive,
                      'button-disabled': _vm.bloq
                    },on:{"click":function($event){{
                        _vm.clickCor('verde'), _vm.Jogada(0)
                      }}}}),_c('div',{staticClass:"QuadradoY col-xs-2"}),_c('div',{staticClass:"Vermelho button col-xs-5",class:{
                      'Vermelho-active': _vm.VermelhoActive,
                      'button-disabled': _vm.bloq
                    },on:{"click":function($event){{
                        _vm.clickCor('vermelho'), _vm.Jogada(1)
                      }}}})]),_c('div',{staticClass:"row QuadradoX"}),_c('div',{staticClass:"row AmareloAzul"},[_c('div',{staticClass:"Amarelo button col-xs-5",class:{
                      'Amarelo-active': _vm.AmareloActive,
                      'button-disabled': _vm.bloq
                    },on:{"click":function($event){{
                        _vm.clickCor('amarelo'), _vm.Jogada(2)
                      }}}}),_c('div',{staticClass:"QuadradoY col-xs-2"}),_c('div',{staticClass:"Azul button col-xs-5",class:{
                      'Azul-active': _vm.AzulActive,
                      'button-disabled': _vm.bloq
                    },on:{"click":function($event){{
                        _vm.clickCor('azul'), _vm.Jogada(3)
                      }}}})])])])])],1)],1)],1),_c('b-col',{attrs:{"md":"11"}},[_c('div',{staticClass:"text-right inf"},[(_vm.memorize == true)?_c('b-badge',{attrs:{"pill":"","variant":"light-danger"}},[_vm._v(" Memorize ")]):_vm._e(),(_vm.jogue == true)?_c('b-badge',{attrs:{"pill":"","variant":"light-success"}},[_vm._v(" Jogue ")]):_vm._e()],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }